@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,600');

a {
  text-decoration: none;
  color: inherit
}


.button-hover{
  text-align: center;
  display: inline-block;
  padding: 10px 40px;
  background-color: #005f9c;
  color:#fff;
  border: 1px solid #fff;
  cursor: pointer;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  margin: 0 20px;
  border-radius: 5px;
}
.button-hover:hover:before{
  left:95%;
}
.button-hover:before{
    content: "";
    position: absolute;
    height: 150px;
    width: 50px;
    background: #fff;
    left: -55px;
    top: -40px;
    transform: rotate(37deg);
    transition: all .3s;
    opacity: 0.3;
}


nav.fill ul li a {
  position: relative;
  padding:10px;
  font-size:18px;
  font-weight: 600;
  
}
nav.fill ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #aaa;
  height: 1px;
  border-radius: 5px;
}
nav.fill ul li a {
  transition: all 1s;
}

nav.fill ul li a:after {
  text-align: left;
  content: '.';
  margin: 0;
  opacity: 0;
}
nav.fill ul li a:hover {
  color: #fff;
  z-index: 1;
}
nav.fill ul li a:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}

@keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #005f9c;
  }
}


.dash-header {
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 35%);
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 35%);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;

}

.social-icons{
  color: #005f9c;
  transition: all .2s ease;
  padding:2px
}

.social-icons:hover{
  color: #00263e;
}


.service-card {
  background: #ededed;
  color: #005f9c;
}

.service-card:hover {
  background: #005f9c;
  color: #f1f1f1;
  transform: scale(1.02);
  box-shadow: 0 10px 24px 0 rgb(0 0 0 / 35%);
  transition: 0.2s ease-in all;
}


.hoverInput {
  border: 1px solid #e1e1e1;
}

.hoverInput:hover {
  border: 1px solid #005f9c;
}

.hover {}

.hover:hover {
  background: #e1e1e1;
  transition: .2s ease all;
  cursor: pointer;
}



.menu-option:hover {
  background-color: #e1e1e1;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.modal {
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 999;
}

.modal.show {
  opacity: 1;
  pointer-events: visible;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modal-content {

  margin-top: 10px;
  width: 80%;
  color: white;

  transition: all 0.3s ease-in-out;
}

.modal.enter-done .modal-content {
  transform: translateY(0);
}

.modal.exit .modal-content {}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-footer {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-title {
  margin: 0;
  font-size: 24px;
}




@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: white;
}

.swiper-pagination-fraction {
  color: white;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.video-button {
  transition: box-shadow .4s cubic-bezier(.2, 0, .7, 1), transform .2s cubic-bezier(.2, 0, .7, 1);
  box-shadow: 0 0 1px 15px hsl(0deg 0% 100% / 40%), 0 0 1px 30px hsl(0deg 0% 100% / 10%), 0 0 1px 45px hsl(0deg 0% 100% / 10%);
}

.video-button:hover {
  box-shadow: none
}

.value-card {
  transition: all 0.2s ease;
}

.value-card:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 2px 2px 8px 4px;
  transform: scale(1.01);
}